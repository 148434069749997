import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
    placeArc,
    placeLogo,
    placeBoldText,
    placeShuffle,
    placeErase
} from '../../../../common/edliy_utils-geometric';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.circle.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -10],keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  makeResponsive(brd1);
  brd1.options.layer['image'] =15;
  brd1.options.layer['point'] =20;
  brd1.options.layer['line'] =10;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Plotting a Point on Coordinate Plane', '');
  placeLogo(brd1);
  var analytics = placeShuffle(brd1);
  analytics.setLabel('Tap to shuffle coordinates')
  analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  analytics.on('over', function () {this.label.setAttribute({visible:true});});
  analytics.on('out', function () {this.label.setAttribute({visible:false});});

  var erase = placeErase(brd1);
  //placeImage(brd1, '/assets/eraser.svg', -2.5, -9.25, 1.5, 0);
  erase.setLabel('Tap to erase and start over')
  erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  erase.on('over', function () {this.label.setAttribute({visible:true});});
  erase.on('out', function () {this.label.setAttribute({visible:false});});
  //placeDash(board, Pt1, Pt2, width, cols)
  //brd1.create('axis', [[-1, 0], [1, 0]], {strokeWidth: 2, strokeColor: 'red'});
  brd1.suspendUpdate();
  var name = placeMiddleText(brd1, 6.5, 14, '');
  name.setAttribute({color:'green'});
  var des = placeMiddleText(brd1, 6.5, 14, '');
  des.setAttribute({color:'green'});
  var i=0; var j=0;
  var Points=[];
  var names=['A', 'B'];
  var subtitle=[];
  var x1 = 3; var y1 = 4; var p; var q;
  var x2 = -4; var y2 = -5;
  subtitle[0] = placeMiddleText(brd1, 0, 8, ()=>'Plot a Point at ( '+ x1.valueOf() + ' , ' + y1.valueOf()+')');
  subtitle[0].setAttribute({visible:()=>i==0});
  subtitle[1] = placeMiddleText(brd1, 0, 8, ()=>'Next, Plot a Point at ( '+ x2.valueOf() + ' , '+ y2.valueOf() + ' )' );
  subtitle[1].setAttribute({visible:()=>i==1});
  subtitle[2] = placeMiddleText(brd1, 0, 8, ()=>'You got ' + j.valueOf() + ' out of 2 correct.');
  subtitle[2].setAttribute({visible:()=>i>1});
  brd1.on('up',
            function (evt)
            {
            if(i<2 && !erase.mouseover && !analytics.mouseover)
            {
            Points[i] = brd1.create('point', brd1.getUsrCoordsOfMouse(evt), {name:'',size:4, snapToGrid:true});
            placeLabel(brd1, Points[i], names[i], 0, 20);
            i+=1;
            }
            if(i==1 && Points[0].X()==x1.valueOf() && Points[0].Y()==y1.valueOf() )
            {
              return j +=1;
            }
            if(i==2 && Points[1].X()==x2.valueOf() && Points[1].Y()==y2.valueOf() )
            {
              return j +=1;
            }
            brd1.update();
            });
            var right1 = placeImage(brd1, '/assets/check.svg', -10+0.5, -10+0.5, 1.,  0);
            var wrong1 = placeImage(brd1, '/assets/cross.svg', -10+0.5, -10+0.5, 1.,  0);
            right1.setAttribute({visible:()=>i>=1 && Points[0].X()==x1.valueOf()&& Points[0].Y()==y1.valueOf()});
            wrong1.setAttribute({visible:()=>i>=1 && (Points[0].X()!=x1.valueOf() || Points[0].Y()!=y1.valueOf())});
            var right2 = placeImage(brd1, '/assets/check.svg', -10+2, -10+0.5, 1.,  0);
            var wrong2 = placeImage(brd1, '/assets/cross.svg', -10+2, -10+0.5, 1.,  0);
            right2.setAttribute({visible:()=>i>=2 && Points[1].X()==x2.valueOf()&& Points[1].Y()==y2.valueOf()});
            wrong2.setAttribute({visible:()=>i>=2 && (Points[1].X()!=x2.valueOf() || Points[1].Y()!=y2.valueOf())});
  brd1.on('up', function()
  {
    if(i==1)
    {
    p =brd1.create('segment',[Points[0], [0.0, ()=>Points[0].Y()]] , {visible:true, name:'p', dash:1, strokeColor:'red', strokeWidth:1});
    q =brd1.create('segment',[Points[0], [()=>Points[0].X(), ()=>0*Points[0].Y()]] , {visible:true,name:'q', dash:1, strokeColor:'red', strokeWidth:1});
    }
    if(i==2)
    {
    p =brd1.create('segment',[Points[1], [0.0, ()=>Points[1].Y()]] , {visible:true, name:'p', dash:1, strokeColor:'red', strokeWidth:1});
    q =brd1.create('segment',[Points[1], [()=>Points[1].X(), ()=>0*Points[0].Y()]] , {visible:true,name:'q', dash:1, strokeColor:'red', strokeWidth:1});
    }
  }
)
 erase.on('down', function()
 {
  brd1.removeObject([Points, p, q]);
  i=0;j=0;
 });
 erase.on('up', function()
 {
  i=0;
  var p;
  var q;
  var Points=[];
 });
 analytics.on('down', function()
 {
   brd1.removeObject([Points, p, q]);
   i=0;j=0;
   x1 = 3+Math.round(3*Math.random());
   y1 = 4+Math.round(3*Math.random());
   ///////////////////////////
   x2 = -4+Math.round(2*Math.random());
   y2 = -5+Math.round(4*Math.random());
});
 /*  function()
  { if(!g)
    {
    g = brd1.create('line',[()=>Points[0], ()=>Points[1]] , {strokeColor:'red', strokeWidth:1});
  }
*/
  /*var PointP = placePoint(brd1, 0, 5, 4, 'blue', 'blue');
  PointP.setAttribute({trace:true});
  var PointQ = placePoint(brd1, ()=>-PointP.X(), ()=>PointP.Y(), 4, 'blue', 'blue');
  PointQ.setAttribute({trace:true});
  var hand = brd1.create('image', ['/assets/hands.svg', [function(){return PointP.X()-0.6;}, function(){return PointP.Y()-0.65;}], [1.1,1.1]], {highlight:false, visible:false});
  PointP.on('over', function(){hand.setAttribute({visible:true});});
  PointP.on('out', function(){hand.setAttribute({visible:false});});
  erase.on('down', function(){PointP.moveTo([0, 5]); PointP.setAttribute({trace:false}); PointQ.setAttribute({trace:false});  PointP.setAttribute({trace:true}); PointQ.setAttribute({trace:true}); });
*/

  /*
  var sq = placeImage(brd1, '/assets/setSquare.svg', 4, 0, 4, 0);
  sq.on('down', function(){name.setText('This is a Set Square.'); des.setText('A set square is used for drawing parallel and perpendicular lines.'); });
  sq.on('out', function(){name.setText(''); des.setText('')}); */
  //Title and subtitleplaceSlider(board, positionX, positionY, limit1, start, limit2, length, name)
/*  var radius = placeSlider(brd1, 0, -5, 1, 1.5, 2.5, 5, '&nbsp; R(m)');
  radius.setAttribute({unitLabel:'m'});
  radius.setAttribute({snapWidth:0.5});



  var go = placeImage(brd1, '/assets/go.svg', 10, -6, 2, 0);
  go.setLabel('Tap to roll the circle.')
  go.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  go.on('over', function () {this.label.setAttribute({visible:true});});
  go.on('out', function () {this.label.setAttribute({visible:false});});
  placeMiddleText(brd1, 6.5, 9, 'Circumference = Distance between A and B');
  placeMiddleText(brd1, 6.5, 13, 'L = 2πR');
  placeMiddleText(brd1, 6.5, 11, 'Select a radius for the circle. Then hit Go.');
  //var radius = brd1.create('slider',[[15,12],[20,12],[1,2.5,3]], {snapToGrid: false ,face:'circle', size:3, name:'r', strokeColor:'black', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var segment1 = brd1.create('segment', [[0,function(){return radius.Value()}], [function(){return 2*Math.PI*radius.Value()},function(){return radius.Value()}]], {fixed: true, strokeColor: 'black', visible:false});

  var ptCenter = brd1.create('glider', [0,2, segment1],{face:'+' , name:'', label:{fontSize:function(){return 16*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
  var pt1 =brd1.create('point', [function(){return ptCenter.X()},function(){return ptCenter.Y()-radius.Value()}], {fixed:false, visible:false, face:'circle', size:4, name:'B', label: {offset: [0,-20]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  brd1.create('point', [ptCenter.X(),ptCenter.Y()-radius.Value()], {fixed:false, visible:true, face:'circle', size:2, name:'A', label: {offset: [0,-20]}, strokeColor: 'red', fillColor: 'red'});
  ///////////////////////////////////////////////////
  var c1 = brd1.create('circle', [ptCenter, pt1], {strokeColor: 'black', strokeWidth:0.1, fillColor:'orange', fillOpacity:0.5});
  ////////////////
  go.on('down', function(){ptCenter.moveTo([2*Math.PI*radius.Value(), radius.Value()], 500)});

  radius.on('drag', function(){ptCenter.moveTo([0, radius.Value()])});
  //
  function angle1(){
      return  (2*Math.PI-ptCenter.X()/radius.Value() );
  }
//////////////////////////////////////////////////
  var pt3 =brd1.create('point', [function(){return ptCenter.X()+radius.Value()*Math.sin(angle1())},function(){return ptCenter.Y()-radius.Value()*Math.cos(angle1())}],
  {fixed:false, visible:true, face:'circle', size:2, name:'B', label: {offset: [0,-20], visible:function(){return ptCenter.X()>2*Math.PI*radius.Value()-0.1;}}, strokeColor: 'red', fillColor: 'red'});
  //pt3.label.setAttribute({visible:true});
  //placeArrow(brd1, [()=>ptCenter.X(), ()=>ptCenter.Y()], [()=>pt3.X(), ()=>pt3.Y()], 2, 'black');
  //var txt1 = brd1.create('text', [3, -4,  function(){return 'ANGLE = ' + angle1()*180/Math.PI}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

  var segment2 = brd1.create('segment', [[0,0], [function(){return pt1.X()},0]], {fixed: true, strokeColor: 'red', visible:true});

  var arc1 = brd1.create('arc', [ptCenter, pt1, pt3], {strokeColor: 'red', strokeWidth: 2});


  //Arrow
  var ptArrow1 =brd1.create('point', [function(){return pt1.X()} ,-2], {fixed:true, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  var ptArrow2 =brd1.create('point', [0 ,-2], {fixed:false, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  var segment2 = brd1.create('segment', [ptArrow1, ptArrow2], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                                                  highlightStrokeWidth: 2,
                                                                                                                  lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                  touchLastPoint: false,
                                                                                                                  firstArrow: {type: 2, size: 8}
                                                                                                                  });

  var txtPer = brd1.create('text', [function(){return pt1.X()/2}, -1.5,  function(){return ''+pt1.X().toFixed(2)+' m'}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);


  var ptArrow3 =brd1.create('point', [-5,0], {fixed:true, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  var ptArrow4 =brd1.create('point', [-5, function(){return radius.Value()}], {fixed:false, visible:false, face:'circle', size:4, name:' ', label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1'});
  var segment3 = brd1.create('segment', [ptArrow3, ptArrow4], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                                                  highlightStrokeWidth: 2,
                                                                                                                  lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                  touchLastPoint: false,
                                                                                                                  firstArrow: {type: 2, size: 8}
                                                                                                                  });

  var txtRad = brd1.create('text', [-4, function(){return radius.Value()/2},  function(){return radius.Value().toFixed(2)}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}},);

*/

  brd1.unsuspendUpdate();
},
}
export default Boxes;
