<template>
  <div>
    <h3 ref="intro">
      Locating a Point on X-Y Plane
    </h3>
    <p> Imagine you were given a point P with coordinates \(a\) and \(b\). To plot this point on Cartesian plane &mdash; </p>
    <ul class="a">
      <li> Start at the origin (i.e. the point with coordinates (0, 0)). Then move \(a\) units along the x-axis. A positive sign for \(a\) would mean moving to the right of the origin while a negative sign of \(a\) would mean moving to the left of the origin. </li>
      <li>
        Starting from the previous step, now move \(b\) units along the y-axis. A positive sign for \(b\) would mean moving upward while a negative sign for \(b\) would mean moving downward.
      </li>
    </ul>
    <br>
    <h3 ref="pg">
      Learn Interactively | Plot a Point on X-Y Plane
    </h3>
    <p> Through this visually interactive module, students will learn to plot a point on Cartesian plane from its x-y coordinates. To plot a point P with coordinates \(a\) and \(b\) on Cartesian plane &mdash; </p>
    <ul class="a">
      <li> Start at the origin (i.e. the point with coordinates (0, 0)). Then move \(a\) units along the x-axis. A positive sign for \(a\) would mean moving to the right of the origin while a negative sign of \(a\) would mean moving to the left of the origin. </li>
      <li>
        Starting from the previous step, now move \(b\) units along the y-axis. A positive sign for \(b\) would mean moving upward while a negative sign for \(b\) would mean moving downward.
      </li>
    </ul>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Tools',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Draw a Point on Cartesian Plane';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is Symmetry?', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Common Geometric Tools', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceMath', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Draw a Line',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
